import { paths as AccountingTypes } from './types/Accounting';
import { paths as AuthenticationTypes } from './types/Authentication';
import { paths as ContentTypes } from './types/Content';
import { paths as UsersTypes } from './types/Users';
import { paths as PartnersTypes } from './types/Partners';
import createClient, { RequestBody, ResponseData, Query, HttpMethod } from './openApiFetch';

import { FetchError } from '../error';
import { getToken } from '../token';
import config from '../config';

export type {
  AccountingTypes,
  AuthenticationTypes,
  ContentTypes,
  UsersTypes,
  PartnersTypes,
  RequestBody,
  ResponseData,
  Query,
};

// We are missing types for the error response body
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createError(body: any, response: Response) {
  let errorType = 'Server error';
  if (response.status >= 400 && response.status < 500) {
    errorType = 'Client error';
  }

  const message = body ? JSON.stringify(body) : response.statusText;

  const error = new FetchError(
    `${errorType}: ${response.status} ${message}`,
    errorType,
    response.status,
    body,
  );

  return error;
}

type RequestOptions = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers?: Record<string, string>;
  body?: string | FormData;
};

async function rawFetch<Paths extends object, P extends keyof Paths, M extends HttpMethod>(
  url: string,
  options?: RequestOptions,
): Promise<{
  data: ResponseData<Paths, P, M>;
  response: Response;
}>;

async function rawFetch<T>(
  url: string,
  options: RequestOptions = {},
): Promise<{ data: T | undefined; response: Response }> {
  const headers: RequestOptions['headers'] = {
    Authorization: `Bearer ${getToken()}`,
    'x-application-name': 'enterprise',
    ...options.headers,
  };

  if (options.body && !headers['Content-Type'] && !(options.body instanceof FormData)) {
    headers['Content-Type'] = 'application/json';
  }

  const response = await fetch(url, {
    method: options.method || 'GET',
    headers,
    body: options.body,
    credentials: 'include',
  });

  if (!response.ok) {
    await handleErrors(response);
  }

  if (response.status === 204) {
    return { response, data: undefined };
  }

  const data = await parseData(response);

  return { data, response };

  async function parseData(response: Response) {
    if (response.headers.get('content-type')?.includes('application/json')) {
      return await response.clone().json();
    }

    return await response.clone().text();
  }

  async function handleErrors(response: Response) {
    let json;
    try {
      json = await response.json();
      // eslint-disable-next-line no-empty
    } catch (_) {}

    throw createError(json, response);
  }
}

function createGalaxyClient({
  token,
  baseUrl = config.API_URL,
}: {
  token?: string;
  baseUrl?: string;
}) {
  const headers = token
    ? { Authorization: `Bearer ${token}`, 'x-application-name': 'enterprise' }
    : { 'x-application-name': 'enterprise' };
  return {
    authentication: createClient<AuthenticationTypes>({
      baseUrl: `${baseUrl}authentication`,
      headers,
    }),
    accounting: createClient<AccountingTypes>({
      baseUrl: `${baseUrl}accounting`,
      headers,
    }),
    content: createClient<ContentTypes>({
      baseUrl: `${baseUrl}content`,
      headers,
    }),
    partners: createClient<PartnersTypes>({
      baseUrl: `${baseUrl}partners`,
      headers,
    }),
    users: createClient<UsersTypes>({
      baseUrl: `${baseUrl}users`,
      headers,
    }),
    fetch: rawFetch,
  };
}

let clientSingleton: ReturnType<typeof createGalaxyClient>;
let cdnClientSingleton: ReturnType<typeof createGalaxyClient>;

/**
 * NOTE: we need to export the client as a function in this way, contrary to
 * how it's done in galaxy, bosse and alliance, because we need to make sure
 * that the openapi-fetch client is created after the msw server.listen is
 * started in the scenario tests, for the SSR-dependent scenario tests to work
 * as expected.
 *
 * Read more: https://openapi-ts.dev/openapi-fetch/testing#mocking-responses
 */
export function client({ token, baseUrl }: { token?: string; baseUrl?: string } = {}) {
  if (!clientSingleton) {
    clientSingleton = createGalaxyClient({ token, baseUrl });
  }

  return clientSingleton;
}

/**
 * NOTE: we need to export the cdnClient as a function in this way because we
 * need to make sure that the openapi-fetch client is created after the msw
 * server.listen is started in the scenario tests, for the SSR-dependent scenario
 * tests to work as expected.
 *
 * Read more: https://openapi-ts.dev/openapi-fetch/testing#mocking-responses
 */
export function cdnClient() {
  if (!cdnClientSingleton) {
    cdnClientSingleton = createGalaxyClient({ baseUrl: config.CDN_API_URL });
  }

  return cdnClientSingleton;
}

import getConfig from 'next/config';
import publicRuntimeConfigForTyping from '../../config/publicRuntimeConfig';
import isBrowser from './helpers/isBrowser';
import { getApiUrl, getCdnApiUrl, getHostUrl } from './urls';

const { publicRuntimeConfig } = getConfig() as {
  publicRuntimeConfig: typeof publicRuntimeConfigForTyping;
};

/**
 * Uses relative url for browsers so using 'localhost' is not a must in dev-staging
 */
const apiBaseUrlGetter = (getApiBaseUrl: (env: string, apiUrl?: string) => string) => {
  if (
    (publicRuntimeConfig.ENV === 'dev-staging' ||
      publicRuntimeConfig.ENV === 'test-staging' ||
      publicRuntimeConfig.ENV === 'dev-production') &&
    isBrowser()
  ) {
    return `/dev-proxy/`;
  }
  return getApiBaseUrl(publicRuntimeConfig.ENV, publicRuntimeConfig.GALAXY_URL);
};

const getCDRCategoryId = (env: string) => {
  switch (env) {
    case 'staging':
    case 'dev-staging':
    case 'test-staging':
      return 'cat_clx1uktmz000009miai369y7c';
    case 'production':
    case 'dev-production':
      return 'cat_cm03vvc3o000009nwff4lak9p';
    default:
      return '';
  }
};

const config = {
  ...publicRuntimeConfig,
  CDR_CATEGORY_ID: publicRuntimeConfig.CDR_CATEGORY_ID || getCDRCategoryId(publicRuntimeConfig.ENV),
  HOST_URL: getHostUrl(publicRuntimeConfig.ENV),
  CDN_API_URL: apiBaseUrlGetter(getCdnApiUrl),
  API_URL: apiBaseUrlGetter(getApiUrl),
};
export default config;

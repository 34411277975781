import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';
import { QueryClientProvider } from '@tanstack/react-query'; //TODO Perf: load on-demand
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router'; // https://github.com/vercel/next.js/issues/18127
import { useEffect, useRef, useState } from 'react';
import { Cookies, CookiesProvider } from 'react-cookie';
import KHTekaLight from '../assets/fonts/KHTeka-Light.woff2';
import KHTekaMedium from '../assets/fonts/KHTeka-Medium.woff2';
import KHTekaRegular from '../assets/fonts/KHTeka-Regular.woff2';
import Favicon from '../assets/images/favicon.png';
import ErrorBoundary from '../common-components/component-library/ErrorBoundary';
import Loader from '../common-components/component-library/Loader';
import Footer from '../common-components/component-library/page-sections/Footer';
import Header from '../common-components/component-library/page-sections/Header';
import TrackingManager from '../common-components/component-library/page-sections/TrackingManager';
import Fonts from '../common-components/component-library/style/Fonts';
import Normalize from '../common-components/component-library/style/Normalize';
import UnsupportedBrowserModal from '../common-components/component-library/UnsupportedBrowserModal';
import MetaHeader from '../common-components/contentful-elements/MetaHeader';
import { LayoutProps } from '../types/LayoutTypes';
import { EVENT_NAMES, sendEvent } from '../utils/analytics';
import queryClientInitialized from '../utils/api/queryClient';
import { getAssetUrlWithAssetPrefix } from '../utils/assetUrl';
import careersLog from '../utils/careers';
import { ApiError, UrlError } from '../utils/error';
import getMetadataSocialImage from '../utils/getMetadataSocialImage';
import useLocale from '../utils/hooks/useLocale';
import { colors } from '../utils/styleguide';
import { WithAppProps } from '../utils/withAppProps';
import config from '../utils/config';
import ErrorPage from './_error';

const Layout = styled.div<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor ?? colors.white};
  position: relative;
  z-index: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

/**
 * Each layout component has its own stacking context,
 * so it shouldn't be needed to use zIndex inside them.
 *
 * The zIndexes used here are used to order the stacking
 * contexts themselves
 * An alternative would be to have the same 0 value for
 * all of them and put them in the expected stacking order.
 *
 * See https://philipwalton.com/articles/what-no-one-told-you-about-z-index/
 */
const HeaderStackingContext = styled(Header)`
  width: 100%;
  position: absolute;
  z-index: 1;
  ${({ floating }) =>
    !floating &&
    css`
      position: relative;
    `}
`;

const ComponentStackingContext = styled.div`
  position: relative;
  z-index: 0;
`;

const FooterStackingContext = styled(Footer)`
  position: relative;
  z-index: 0;
`;

const LoaderStackingContext = styled(Loader)`
  position: fixed;
  z-index: 2;
`;
const GlobalStyles = css`
  // Disable style for Cypress tests as a workaround for https://github.com/cypress-io/cypress/issues/23355
  html:not(.cypress-tests) {
    scroll-behavior: smooth;
  }
`;

type InitialProps = {
  error?: Error | ApiError | UrlError | { error: number };
  cookies?: Cookies;
};

const MyApp = (props: AppProps & InitialProps) => {
  const queryClient = useRef(queryClientInitialized).current; // don't reuse query client for tests to avoid sharing react query
  const [isLoading, setIsLoading] = useState(false);
  const [defaultAppProps, setDefaultAppProps] = useState<WithAppProps['appProps']>();
  const language = useLocale();

  useEffect(() => {
    const handlerRouteChange = () => {
      let fromPath: string | undefined;
      const loadStart = () => {
        fromPath = window.location.pathname;
        setIsLoading(true);
      };
      const loadComplete = () => {
        sendEvent({
          name: EVENT_NAMES.viewPage,
          properties: {
            'utm source': Router.query.utm_source as string,
            referrer: document.referrer,
            language,
            country: Router.query.country as string,
            path: window.location.pathname,
            'from path': fromPath,
          },
        });
        setIsLoading(false);
      };
      Router.events.on('routeChangeStart', loadStart);
      Router.events.on('routeChangeComplete', loadComplete);
      Router.events.on('routeChangeError', loadComplete);
      return () => {
        Router.events.off('routeChangeStart', loadStart);
        Router.events.off('routeChangeComplete', loadComplete);
        Router.events.off('routeChangeError', loadComplete);
      };
    };

    const unregisterRouterEvents = handlerRouteChange();

    sendEvent({
      name: EVENT_NAMES.webSessionStart,
      properties: undefined,
    });
    sendEvent({
      name: EVENT_NAMES.viewPage,
      properties: {
        'utm source': Router.query.utm_source as string,
        referrer: document.referrer,
        language,
        country: Router.query.country as string,
        path: window.location.pathname,
      },
    });

    if (process.env.NODE_ENV !== 'test') {
      careersLog();
    }

    return () => {
      unregisterRouterEvents();
    };
  }, [language]);

  const {
    Component,
    pageProps,
  }: {
    Component: typeof props.Component;
    pageProps?: Partial<WithAppProps>;
  } = props;

  const appPropsFromPage = pageProps?.appProps || defaultAppProps;
  /**
   * This a fallback in case the page:
   * - did not declare `getServerSideProps` together with  `withAppProps`,
   *
   * It will show default values client-side
   *
   * When using server-side rendering, it should be avoided because of layout jump
   */
  useEffect(() => {
    if (!appPropsFromPage) {
      import('../utils/withAppProps').then(({ getDefaultAppProps }) => {
        getDefaultAppProps().then((defaultAppProps) => setDefaultAppProps(defaultAppProps));
      });
    }
  }, [appPropsFromPage]);

  // @ts-expect-error Property 'layoutProps' does not exist on type 'NextComponentType<NextPageContext, any, {}>'.
  const componentLayoutProps = Component.layoutProps;
  const {
    backgroundColor,
    floatingHeader: floating,
    initiallyTransparentHeader,
    hideNavbarLogo,
  }: LayoutProps = componentLayoutProps || appPropsFromPage?.layoutProps || {};

  const { metaHeader, navigationBar, footerBar, error, disableTrackingConsent, partnerLogoSrc } =
    appPropsFromPage || {};

  return (
    <ErrorBoundary>
      <CookiesProvider>
        <Head>
          <link
            rel="preload"
            href={getAssetUrlWithAssetPrefix(KHTekaRegular)}
            as="font"
            crossOrigin="anonymous"
          />
          <link
            rel="preload"
            href={getAssetUrlWithAssetPrefix(KHTekaLight)}
            as="font"
            crossOrigin="anonymous"
          />
          <link
            rel="preload"
            href={getAssetUrlWithAssetPrefix(KHTekaMedium)}
            as="font"
            crossOrigin="anonymous"
          />
          <link rel="icon" type="image/png" href={Favicon} />
          <Normalize />
          <Fonts />
        </Head>

        <Global styles={GlobalStyles} />
        {metaHeader && <MetaHeader metaHeader={metaHeader} />}
        {!metaHeader && <DefaultMetaHeader />}
        <UnsupportedBrowserModal />
        <QueryClientProvider client={queryClient}>
          <Layout backgroundColor={backgroundColor}>
            <TrackingManager disableTrackingConsent={disableTrackingConsent} />
            {navigationBar && (
              <HeaderStackingContext
                navigationBar={navigationBar}
                floating={floating !== undefined ? floating : !!navigationBar.poweredByMilkywire}
                initiallyTransparent={initiallyTransparentHeader}
                hideNavbarLogo={hideNavbarLogo}
                partnerLogoSrc={partnerLogoSrc}
              />
            )}
            <ComponentStackingContext>
              {error ? (
                <ErrorPage statusCode={'status' in error ? error.status : 500} />
              ) : (
                <ErrorBoundary>
                  <Component floating={floating} {...pageProps} />
                </ErrorBoundary>
              )}
            </ComponentStackingContext>
            {footerBar && <FooterStackingContext footerBar={footerBar} />}
            {isLoading && <LoaderStackingContext overlay />}
          </Layout>
          {config.ENV !== 'test' && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
      </CookiesProvider>
    </ErrorBoundary>
  );
};

const DEFAULT_META_TITLE = 'Milkywire – Support local charities to make an impact';
const DEFAULT_META_DESCRIPTION =
  'Meet the people changing the world. The ones that work tirelessly in the field every day to make a difference for their cause. Join them on their journey to make a long-standing impact.';
const DEFAULT_META_IMAGE = getMetadataSocialImage();

const DefaultMetaHeader = () => (
  <Head>
    <title>{DEFAULT_META_TITLE}</title>
    <meta key="og:title" property="og:title" content={`Milkywire - Support local charities`} />
    <meta key="description" name="description" content={DEFAULT_META_DESCRIPTION} />
    <meta key="og:description" property="og:description" content={DEFAULT_META_DESCRIPTION} />
    <meta key="og:image" property="og:image" content={DEFAULT_META_IMAGE} />
    <meta key="og:image:width" property="og:image:width" content="1200" />
    <meta key="og:image:height" property="og:image:height" content="628" />
    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    <script
      key="schema.org"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'http://schema.org/',
          '@type': 'organization',
          name: DEFAULT_META_TITLE,
          image: DEFAULT_META_IMAGE,
          description: DEFAULT_META_DESCRIPTION,
        }),
      }}
    />
  </Head>
);

export default MyApp;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode, CSSProperties } from 'react';
import { breakpoints, spacing } from '../../../utils/styleguide';
import Image from 'next/image';

const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const ImageStyle = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const OuterContainer = styled.div<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  justify-content: center;
  position: relative;
`;

const InnerContainer = styled.div<{
  disableVerticalPadding: boolean;
}>`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 1440px;
  padding: ${spacing[5]}px ${spacing[4]}px ${spacing[9]}px;
  overflow: hidden;

  ${breakpoints.tablet} {
    padding: ${spacing[6]}px ${spacing[6]}px ${spacing[9]}px;
  }

  ${breakpoints.desktop} {
    padding: ${spacing[9]}px ${spacing[11]}px 96px;
  }

  ${({ disableVerticalPadding }) =>
    disableVerticalPadding &&
    css`
      padding-top: 0;
      padding-bottom: 0;

      ${breakpoints.tablet} {
        padding-top: 0;
        padding-bottom: 0;
      }

      ${breakpoints.desktop} {
        padding-top: 0;
        padding-bottom: 0;
      }
    `}
`;

const ImageOverlay = styled.div<{ overlayPercentage: number }>`
  background: ${({ overlayPercentage }) => {
    const rgba = `rgba(0, 0, 0, ${(overlayPercentage ?? 100) / 100})`;

    return `linear-gradient(0deg, ${rgba}, ${rgba})`;
  }};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

function Container({
  children,
  backgroundColor,
  backgroundImage,
  backgroundImageAlt,
  imageOverlayPercentage,
  disableVerticalPadding = false,
  style,
  className,
  lazyLoad,
  id,
}: {
  children: ReactNode;
  backgroundColor?: string | null;
  backgroundImage?: string;
  backgroundImageAlt?: string;
  imageOverlayPercentage?: number | null;
  disableVerticalPadding?: boolean;
  style?: CSSProperties;
  className?: string;
  lazyLoad?: boolean;
  id?: string;
}) {
  // next/image doesn't support protocol-relative URL, it requires the src to have a protocol
  const imageSrc = backgroundImage?.startsWith('//') ? `https:${backgroundImage}` : backgroundImage;
  return (
    <OuterContainer
      id={id?.toLocaleLowerCase().replaceAll(' ', '-')}
      backgroundColor={backgroundColor ?? undefined}
      style={style}
    >
      {imageSrc && (
        <ImageWrapper>
          <Image
            width={4000}
            height={4000}
            src={imageSrc}
            css={ImageStyle}
            alt={backgroundImageAlt ?? ''}
            loading={lazyLoad ? 'lazy' : 'eager'}
            sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, (max-width: 3000px) 1600px, 4000px"
          />
          {imageOverlayPercentage && imageOverlayPercentage > 0 ? (
            <ImageOverlay overlayPercentage={imageOverlayPercentage} />
          ) : null}
        </ImageWrapper>
      )}
      <InnerContainer className={className} disableVerticalPadding={disableVerticalPadding}>
        {children}
      </InnerContainer>
    </OuterContainer>
  );
}

export default Container;
